import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import BlogPostPreview from "../BlogPostPreview";
import { media, font, Base } from "../styled";

const BlogPostPreviewGrid = ({ title, nodes, browseMoreHref }) => (
  <Root>
    {title && <Title>{title}</Title>}
    <Grid>
      {nodes &&
        nodes.map((node) => (
          <li key={node.id}>
            <BlogPostPreview
              publishedAt={node.publishedAt}
              slug={node.slug}
              mainImage={node.mainImage}
              title={node.title}
              rawExcerpt={node._rawExcerpt}
              tags={node.tags}
            />
          </li>
        ))}
    </Grid>
    {browseMoreHref && (
      <BrowseMoreNav>
        <Link to={browseMoreHref}>Browse more</Link>
      </BrowseMoreNav>
    )}
  </Root>
);

BlogPostPreviewGrid.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: "",
};

const Root = styled.div`
  margin: 1em 0 4em;
`;

const Grid = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2em;

  ${media.minSmall} {
    grid-template-columns: 1fr 1fr;
  }

  ${media.minMedium} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Title = styled.h2`
  font-size: ${font.smallSize};
  line-height: ${font.smallLineHeight};
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 1rem 0 2rem;
`;

const BrowseMoreNav = styled(Base)`
  margin-top: 1rem;
  text-align: right;

  a {
    display: inline-block;
    padding: 0.5rem 0;
    color: inherit;
    text-decoration: none;

    @media (hover: hover) {
      &:hover {
        color: ${(props) => props.theme.accentColor};
      }
    }
  }
`;

export default BlogPostPreviewGrid;
