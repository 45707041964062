import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from "../helpers";
import BlogPostPreviewGrid from "../components/BlogPostPreviewGrid";
import {
  Container,
  SEO,
  GraphQLError,
  SlideShow,
} from "../components/elements";

import Layout from "../containers";

import useFirebaseAnalytics from "../hooks/useFirebaseAnalytics";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      instagram
      enableInstagramFeed
    }
    page: sanityHome(_id: { regex: "/(drafts.|)home/" }) {
      id
      showCarousel
      carousel {
        _key
        _type
        slides {
          _key
          _type
          alt
          caption
          link
          _rawCredit
          reference {
            ... on SanityPost {
              _type
              title
              slug {
                current
              }
            }
            ... on SanityReadingChallenge {
              _type
              title
              slug {
                current
              }
            }
          }
          asset {
            _id
          }
        }
        showNav
      }
    }
    posts: allSanityPost(
      limit: 9
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        publishedAt: { ne: null }
        isBackgroundPost: { ne: true }
      }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
          tags {
            tag {
              title
            }
          }
        }
      }
    }
  }
`;

const IndexPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLError errors={errors} />
      </Layout>
    );
  }

  const { site } = data || {};
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  const { page } = data || {};
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  useFirebaseAnalytics();

  return (
    <Layout>
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
      />
      <Container>
        <h1 hidden>
          Welcome to
          {site.title}
        </h1>
        {page && page.showCarousel && (
          <SlideShow
            slides={page.carousel.slides}
            showNav={page.carousel.showNav}
          />
        )}
        {postNodes && (
          <BlogPostPreviewGrid
            title="Latest blog posts"
            nodes={postNodes}
            browseMoreHref="/books/"
          />
        )}
      </Container>
    </Layout>
  );
};

export default IndexPage;
